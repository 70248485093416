import { useQuery } from "@tanstack/react-query";

import { getAddressByGeoCode } from "../googleMaps";
import { type Location } from "../types";

export function useGetAddressByGeoCode(location: Location) {
  return useQuery({
    queryKey: ["addressByGeoCode", location],
    queryFn: async () => {
      return await getAddressByGeoCode(location);
    },
  });
}
